<template>
  <div id="wrap" class="content-center items-center my-auto mx-auto bg-gray-700">
    <!-- centter my div -->

    <div class="text-center center my-auto w-full -mt-5">
      <p class="tracking-tight font-extrabold w-auto mx-auto text-white">
        <span class="text-3xl sm:text-4xl md:text-5xl 2xl:text:6xl">Purcahse canceled</span>
        <span
          class="block text-1xl sm:text-2xl md:text-3xl h-auto w-full z-20 min-w-10 text-gray-400"
        >
          We've canceled your payment!</span
        >
      </p>
    
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {},
};
</script>

<style>
#wrap {
  height: 100vh;
}
.center {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

</style>
