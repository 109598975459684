<template>
  <div id="body" class="relative mx-auto">
    <svg
      class="fixed z-10 top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
      width="404"
      height="784"
      fill="none"
      viewBox="0 0 404 784"
    >
      <defs>
        <pattern
          id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
          x="0"
          y="0"
          width="22"
          height="21.54"
          patternUnits="userSpaceOnUse"
        >
          <circle cx="3" cy="3" r="3" class="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width="404" height="784" fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
    </svg>

    <svg
      class="fixed z-10 top-0 transform -translate-x-2/2 left-0 translate-y-2/4 lg:right-0 lg:left-auto lg:translate-x-2/3 lg:-translate-y-1/4"
      width="404"
      height="784"
      fill="none"
      viewBox="0 0 404 784"
    >
      <defs>
        <pattern
          id="8b1b5f72-e944-4457-af67-foSiurnKJF"
          x="0"
          y="0"
          width="22"
          height="21.54"
          patternUnits="userSpaceOnUse"
        >
          <circle cx="3" cy="3" r="3" class="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width="404" height="400" fill="url(#8b1b5f72-e944-4457-af67-foSiurnKJF)" />
    </svg>

    <div class="z-20 text-center mt-20 flex">
      <p class="z-20 tracking-tight mx-auto font-extrabold w-96 mt-10 text-gray-900">
        <span class="z-20 text-4xl sm:text-5xl md:text-6xl 2xl:text:9xl"
          >This is Tovy</span
        >
        <span
          class="block text-1xl sm:text-3xl md:text-4xl w-full z-20 min-w-10 text-transparent bg-clip-text bg-gradient-to-tr from-green-500 to-blue-500 to-red-500"
        >
          A fully open source staff management platform</span
        >
      </p>
    </div>
    <div
      class="flex flex-col items-center justify-center mt-6 space-y-1 md:flex-row md:space-y-0 md:space-x-3"
    >
      <button
        v-on:click="open('https://github.com/ItsWHOOOP/tovy')"
        class="w-full md:w-auto btn btn-lg btn-light "
      >
        Github
      </button>
      <button
        v-on:click="open('https://tovyblox.xyz/discord')"
        class="w-full md:w-auto btn btn-lg btn-light text-white bg-pink-600 "
      >
        Discord
      </button>
      <button
        v-on:click="open('https://docs.tovyblox.xyz')"
        class="w-full md:w-auto btn btn-lg btn-light text-white bg-blue-500"
      >
        Docs
      </button>
    </div>
    <div class="flex">
      <div class="mx-auto mt-16 z-30 -text-center w-10/12">
        <img
          src="https://i.imgur.com/y85B5Vk.png"
          alt="Tovy"
          class="w-full rounded-lg shadow-2xl"
        />
      </div>
    </div>
    <div class="flex mx-auto h-[400px]">
      <div class="z-10 bg-gray-700 h-full w-full -mt-36">
        <p class="mx-auto text-center mt-44 font-bold text-white text-4xl ">Used by the best</p>

        <div class="grid grid-cols-2 mt-6 gap-10 text-center lg:grid-cols-6">
          <div
            class="flex items-center justify-center mb-10"
            v-for="(group, i) of groups"
            :key="i"
          >
            <img
              :src="group.logo"
              :alt="`${group.name}`"
              class="block object-contain h-24 rounded-xl"
            />
          </div>
        </div>

        <section class="px-4 py-24 mx-auto max-w-7xl">
          <div
            id="activity"
            class="grid items-center grid-cols-1 mb-24 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24"
          >
            <div>
              <h2
                class="mb-4 text-2xl font-extrabold tracking-tight text-center text-white md:leading-tight sm:text-left md:text-4xl"
              >
                Activity tracking
              </h2>
              <p class="mb-5 text-base text-center text-gray-200 sm:text-left md:text-lg">
                Tovy has an amazing activity tracking system that lets you see how much
                time staff from your group have spent in-in-game
              </p>
            </div>
            <div class="w-full h-full rounded-xl bg-gray-200">
              <img
                src="https://images-ext-2.discordapp.net/external/YkXUz_R6WEALmyleUcENOM-3TQdy8E9KjgHYPPz4GmQ/https/cdn.iharrblx.xyz/firefox_5SxbH4MP3p.png"
                alt="Hellonext feedback boards software screenshot"
                class="w-full rounded-lg shadow-2xl"
              />
            </div>
          </div>

          <div
            id="wall"
            class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24"
          >
            <div class="order-none md:order-2">
              <h2
                class="mb-4 text-2xl font-extrabold tracking-tight text-center text-white md:leading-tight sm:text-left md:text-4xl"
              >
                Wall
              </h2>
              <p class="mb-5 text-base text-center text-gray-200 sm:text-left md:text-lg">
                Communicate with your group on tovy. Synces wtih your group shout and
                discord. All in realtime.
              </p>
            </div>
            <div class="w-full h-full rounded-xl bg-gray-200">
              <img
                src="https://images-ext-2.discordapp.net/external/oeL0hEX81-7FvcEbPwR9pj5NGQ0V3f_OXm_dvL-PBDU/https/cdn.iharrblx.xyz/firefox_6unVDTBTWF.png"
                alt="Hellonext feedback boards software screenshot"
                class="w-full rounded-lg shadow-2xl"
              />
            </div>
          </div>

          <div
            id="sessions"
            class="grid items-center grid-cols-1 mb-24 md:grid-cols-2 gap-y-10 mt-10 md:gap-y-32 gap-x-10 md:gap-x-24"
          >
            <div>
              <h2
                class="mb-4 text-2xl font-extrabold tracking-tight text-center text-white md:leading-tight sm:text-left md:text-4xl"
              >
                Sessions
              </h2>
              <p class="mb-5 text-base text-center text-gray-200 sm:text-left md:text-lg">
                Manage all your sessions with tovy. You can create sessions for later if you need to. Synces with discord
              </p>
            </div>
            <div class="w-full h-full rounded-xl bg-gray-200">
              <img
                src="https://images-ext-2.discordapp.net/external/w46AB9ZJqyR60_hWZ-hhAgVDpjYetnrGE5cfRtAImQw/https/cdn.iharrblx.xyz/firefox_faFspxAyP8.png"
                alt="Hellonext feedback boards software screenshot"
                class="w-full rounded-lg shadow-2xl"
              />
            </div>
          </div>
          <div
            id="automation"
            class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24"
          >
            <div class="order-none md:order-2">
              <h2
                class="mb-4 text-2xl font-extrabold tracking-tight text-center text-white md:leading-tight sm:text-left md:text-4xl"
              >
                Automation
              </h2>
              <p class="mb-5 text-base text-center text-gray-200 sm:text-left md:text-lg">
                Automate things on Tovy! For example when a session is hosted shout that there is a session going on.
              </p>
            </div>
            <div class="w-full h-full rounded-xl bg-gray-200">
              <img
                src="https://images.grplaza.org/bot/78062b6211fe02c21ad973ebbf8f8b6e/c02c9a9f5d0eec93f269ff972fde8818.png"
                alt="Hellonext feedback boards software screenshot"
                class="w-full rounded-lg shadow-2xl"
              />
            </div>
          </div>
          <div
            id="tasks"
            class="grid items-center grid-cols-1 mb-24 md:grid-cols-2 gap-y-10 mt-10 md:gap-y-32 gap-x-10 md:gap-x-24"
          >
            <div>
              <h2
                class="mb-4 text-2xl font-extrabold tracking-tight text-center text-white md:leading-tight sm:text-left md:text-4xl"
              >
                Tasks
              </h2>
              <p class="mb-5 text-base text-center text-gray-200 sm:text-left md:text-lg">
                Assign tasks to your staff members! Set priority, assign to a role or two, assign to that pesky admin, and more.
              </p>
            </div>
            <div class="w-full h-full rounded-xl bg-gray-200">
              <img
                src="https://images.grplaza.org/bot/9d3df904c8dbc56d06f2045b88ca8e6e/b31da32b910ed79d9692d6f8e9a66608.png"
                alt="Hellonext feedback boards software screenshot"
                class="w-full rounded-lg shadow-2xl"
              />
            </div>
          </div>

        </section>

        

        <section id="other" class="px-4 py-20 mx-auto max-w-7xl -mt-20">
          <h2 class="mb-1 text-3xl font-extrabold leading-tight text-white">
            Theres more
          </h2>
          <p class="mb-16 text-lg text-gray-200">We have more features then just that!</p>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-16 lg:gap-x-24 gap-y-20"
          >
            <div>
              <div
                class="flex items-center justify-center w-8 h-8 mb-4 text-red-600 bg-red-100 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 class="mb-2 text-base font-semibold leading-tight text-white">
                Fast af
              </h3>
              <p class="text-sm text-gray-200">
                Tovy runs super fast so your group doesnt miss a beat
              </p>
            </div>
            <div>
              <div
                class="flex items-center justify-center w-8 h-8 mb-4 text-pink-600 bg-pink-100 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 class="mb-2 text-base font-semibold leading-tight text-white">
                Customization
              </h3>
              <p class="text-sm text-gray-200">
                Tovy is super customizible! You can change the feel of tovy to fit your
                group!
              </p>
            </div>
            <div>
              <div
                class="flex items-center justify-center w-8 h-8 mb-4 text-yellow-600 bg-yellow-100 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 class="mb-2 text-base font-semibold leading-tight text-white">
                Granular permissions
              </h3>
              <p class="text-sm text-gray-200">
                Tovy lets you control what each role can do with Tovy.
              </p>
            </div>
            <div>
              <div
                class="flex items-center justify-center w-8 h-8 mb-4 text-green-600 bg-green-100 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 class="mb-2 text-base font-semibold leading-tight text-white">
                Invites
              </h3>
              <p class="text-sm text-gray-200">
                Tovy has an invite system so you can invite your group to use Tovy.
              </p>
            </div>
          </div>
        </section>

        <section id="other" class="px-4 py-20 mx-auto max-w-7xl">
          <h2 class="mb-1 text-3xl font-extrabold leading-tight text-white">
            What people say
          </h2>
          <p class="mb-16 text-lg text-gray-200">Here is what groups say about tovy</p>
          <div class="carousel-inner relative overflow-hidden w-full">
            <div
              v-for="(item, i) in test.items"
              :id="`slide-${i}`"
              :key="i"
              :class="`${test.active === i ? 'active' : 'left-full'}`"
              class="carousel-item inset-0 relative w-full transform transition-all duration-500 ease-in-out"
            >
              <div class="w-full py-20 mx-auto text-center max-w-7xl md:w-4/5 lg:w-4/6">
                <h1
                  class="mt-3 mb-10 text-xl font-extrabold text-white md:leading-snug md:text-3xl"
                >
                  "{{ item.text }}"
                </h1>
                <div class="mx-auto mb-3 shadow-lg avatar">
                  <img :src="item.user.avatar" alt="Praveen Juge" />
                </div>
                <p class="text-base font-medium text-gray-200">{{ item.user.name }}</p>
                <p class="text-xs font-medium text-gray-400">{{ item.group.title }}</p>
              </div>
            </div>
          </div>
          <div
            class="carousel-indicators -mt-10 flex h-24 w-full justify-center items-center"
          >
            <ol class="z-50 flex w-4/12 justify-center">
              <li
                v-for="(img, i) in test.items"
                :key="i"
                @click="test.active = i"
                :class="`md:w-4 md:h-4 ${
                  test.active == i ? 'bg-white' : 'bg-gray-300'
                } rounded-full cursor-pointer mx-2`"
              ></li>
            </ol>
          </div>
        </section>

        <footer class="bg-white py-12 mx-auto">
          <div
            class="grid grid-cols-2 px-6 gap-10 mb-3 md:grid-cols-3 lg:grid-cols-11 lg:gap-20"
          >
            <div class="col-span-3">
              <p class="my-4 text-xs leading-normal text-gray-600">
                This landing page is based on
                <a href="https://kutty.netlify.app" class="underline" target="_blank"
                  >kutty</a
                >. Tovy was made with ♥️ in the 🇺🇸 by ItsWHOOOP
              </p>
            </div>
            <nav class="col-span-1 md:col-span-1 lg:col-span-2">
              <p
                class="mb-3 text-xs font-semibold tracking-wider text-gray-400 uppercase"
              >
                App
              </p>
              <a
                class="flex mb-3 text-sm font-medium text-gray-800 transition md:mb-2 hover:text-purple-800"
                href="#activity"
                >Activity tracking</a
              >
              <a
                class="flex mb-3 text-sm font-medium text-gray-800 transition md:mb-2 hover:text-purple-800"
                href="#wall"
                >Wall</a
              > <a
                class="flex mb-3 text-sm font-medium text-gray-800 transition md:mb-2 hover:text-purple-800"
                href="#sessions"
                >Sessions</a
              >
              <a
                class="flex mb-3 text-sm font-medium text-gray-800 transition md:mb-2 hover:text-purple-800"
                href="#tasks"
                >Tasks</a
              >
              <a
                class="flex mb-3 text-sm font-medium text-gray-800 transition md:mb-2 hover:text-purple-800"
                href="#automation"
                >Automation</a
              >
              <a
                class="flex mb-3 text-sm font-medium text-gray-800 transition md:mb-2 hover:text-purple-800"
                href="#other"
                >More</a
              >
            </nav>
            <nav class="col-span-2 md:col-span-1 lg:col-span-2">
              <p
                class="mb-3 text-xs font-semibold tracking-wider text-gray-400 uppercase"
              >
                Support
              </p>
              <a
                class="flex mb-3 text-sm font-medium text-gray-800 transition md:mb-2 hover:text-purple-800"
                href="https://tovyblox.xyz/discord"
                >Discord server</a
              >
              <a
                class="flex mb-3 text-sm font-medium text-gray-800 transition md:mb-2 hover:text-purple-800"
                href="https://tovy.gitbook.io"
              >
                Guides
              </a>
            </nav>

            <nav class="col-span-1 md:col-span-1 lg:col-span-2">
              <p
                class="mb-3 text-xs font-semibold tracking-wider text-gray-400 uppercase"
              >
                Us
              </p>
              <a
                class="flex mb-3 text-sm font-medium text-gray-800 transition md:mb-2 hover:text-purple-800"
                href="https://github.com/ItsWHOOOP/tovy"
                >Github</a
              >
              <a
                class="flex mb-3 text-sm font-medium text-gray-800 transition md:mb-2 hover:text-purple-800"
                href="https://feedback.tovyblox.xyz"
                >Feedback</a
              >
            </nav>
          </div>
          <div
            class="flex px-6 flex-col items-start justify-between pt-10 mt-10 border-t border-gray-100 md:flex-row md:items-center"
          >
            <p class="mb-2 text-xs text-left text-gray-600 md:mb-0">Built for groups.</p>
            <p class="mb-0 text-xs text-left text-gray-600 md:mb-0">
              Copyright &copy; 2022 ItsWHOOOP
            </p>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {},
  methods: {
    open: function (link) {
      window.open(link);
    },
  },
  data: () => ({
    test: {
      items: [
        {
          text: "Tovy is a very powerful tool we use at Emerald Theatre, it enables us to track all our staff's activity and monitor them throughout the week. It has been a great replacement of other tools we have used in the past. I would highly recommend using Tovy over other tools.",
          user: {
            name: "mqfaa",
            avatar:
              "https://tr.rbxcdn.com/89b4cf5500ea2fcf0ef911e38886706f/150/150/AvatarHeadshot/Png",
          },
          group: {
            title: "CTO, Emerald Theatre",
            link: "https://google.com",
          },
        },
        {
          text: "Tovy has enabled Foxtrot to manage our diverse grouping of divisions through an efficient, easy to use interface.",
          user: {
            name: "AsyncFunctions",
            avatar:
              "https://tr.rbxcdn.com/ad6cc2188ee6abf990a9909832409fce/150/150/AvatarHeadshot/Png",
          },
          group: {
            title: "Developer, Foxtrot Asуlum",
            link: "https://google.com",
          },
        },
        {
          text: "Using Tovy as our staff management system has been nothing but a breeze. It tracks all of our staff's activity, allows them to manage their notices, and even has the option for us to have a custom domain. Support through the Discord server has been phenomenal.",
          user: {
            name: "v1nhtage",
            avatar:
              "https://tr.rbxcdn.com/cbaa36e6481b8b7be98b87f09b66c10f/150/150/AvatarHeadshot/Png",
          },
          group: {
            title: "President, $TARSTRUCK",
            link: "https://google.com",
          },
        },
        {
          text: "Tovy is a fantastic system that saves our team time and makes our job's so much simpler. It is an extremely useful tool at Nature Hotels as we use most aspects of the system. The custom domain support makes Tovy feel extremely customizable. Overall, Tovy is extremely easy to set up and use..",
          user: {
            name: "chr1vsx",
            avatar:
              "https://tr.rbxcdn.com/4fc94612e86075165c5b286bc0d74df8/150/150/AvatarHeadshot/Png",
          },
          group: {
            title: "Owner, Nature Hotels",
            link: "https://google.com",
          },
        },
      ],
      active: 0,
    },
    img: require("../assets/texture.svg"),
    groups: [
      {
        name: "Transport for Applesville",
        logo: "https://tr.rbxcdn.com/d846a51efdd1c2d8a5f8c02112142d17/150/150/Image/Png",
      },
      {
        name: "Emerald Theatre",
        logo: "https://tr.rbxcdn.com/69d4e5853de6062edf1162bb60ff343b/150/150/Image/Png",
      },
      {
        name: 'Burger Express',
        logo: 'https://tr.rbxcdn.com/3270954acaf2166a2595d68c7ad71d88/150/150/Image/Png',
      },
      
      {
        name: "Foxtrot Asуlum",
        logo: "https://tr.rbxcdn.com/8b99a7885b332e02d7874bc54fbe800b/150/150/Image/Png",
      }, {
        name: "Nature Hotels",
        logo: "https://tr.rbxcdn.com/847e4364aa708ff27153156780284c7f/150/150/Image/Png",
      }, {
        name: "Fallen worlds",
        logo: "https://tr.rbxcdn.com/5e860326669133ebdbcc34b7ceff27f5/150/150/Image/Png",
      },
    ],
  }),
  mounted() {
    let i = 0;
    setInterval(() => {
      if (i > this.test.items.length - 1) {
        i = 0;
      }
      this.test.active = i;
      i++;
    }, 4000);
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

.left-full {
  left: -100%;
}

.carousel-item {
  float: left;
  position: relative;
  display: block;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
}

.carousel-item.active {
  left: 0;
}
</style>
